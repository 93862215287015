import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, public apiService: ApiService) {}

  session_token = '';
  session_user_name = '';
  session_user_role = '';

  ngOnInit(): void {
    this.session_token = localStorage.getItem('session_token');
    this.session_user_name = localStorage.getItem('session_user_name');
    this.session_user_role = localStorage.getItem('session_user_role');
  }

  logout(){
    localStorage.removeItem("session_token");
    this.router.navigate([``]);
  }

}
