import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { NotificationsItem } from './notifications-datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<NotificationsItem>;

  // notifications list
  notifications = [];
  displayedColumns = ['title', 'body', 'url', 'type', 'dateFrom', 'dateUntil', 'options'];

  // notification details
  notificationId;
  notification = {
    id: '',
    title: '',
    body: '',
    date_from: '',
    date_until: '',
    url: '',
    created_at: '',
    type: ''
  };
  notificationDataForm: FormGroup;

  // on/off variables
  loading = false;
  showNotificationsList = true;
  showFormType = '';

  // messages
  formErrorMsg = '';
  formTitle = '';

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router,
    private fb: FormBuilder, private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.notificationId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.notificationId) {
      this.showNotificationsList = false;
      this.showFormType = 'edit';
      this.listNotifications(this.notificationId);
    }
    if (!this.notificationId) {
      this.listNotifications();
    }
  }


  /******************************************************************************/
  // NOTIFICATIONS LIST
  
  listNotifications(notificationId?) {
    this.loading = true;
    this.apiService.get('admin/notifications', null).subscribe(
      (result) => {
        if (result.error == "0") {
          this.notifications = result.data[0];

          // update notifications list
          this.notifications = [];
          result.data[0].forEach(n => {
            var not = {
              id: n.id,
              title: n.title,
              body: n.body,
              date_from: null,
              date_until: null,
              url: n.url != "null" ? n.url : '',
              created_at: n.created_at,
              type: n.type
            }

            if (n.date_from) {
              not.date_from = new Date(n.date_from).toISOString().slice(0, 16);
            }
            if (n.date_until) {
              not.date_until = new Date(n.date_until).toISOString().slice(0, 16);
            }

            // data for detail/list
            if (notificationId) {
              if (notificationId == not.id) {
                this.notification = not;
                this.notifications.push(not);
              }
            } else {
              this.notifications.push(not);
            }
          });     
          
          if (notificationId) {
            this.createNotificationForm('edit');
          } else {
            this.table.dataSource = this.notifications;
          }

          this.loading = false;
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.loading = false;
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.loading = false;
      }
    );
  }


  /******************************************************************************/
  // NOTIFICATION ADD/EDIT

  createNotificationForm(type) {
    if (type == 'edit') {
      this.formTitle = 'Edit Notification';
      this.notificationDataForm = this.fb.group({
        id: new FormControl(this.notification.id),
        title: new FormControl(this.notification.title, Validators.required),
        body: new FormControl(this.notification.body, Validators.required),
        date_from: new FormControl(this.notification.date_from, Validators.required),
        date_until: new FormControl(this.notification.date_until, Validators.required),
        url: this.notification.url != "null" ? new FormControl(this.notification.url) : new FormControl(''),
        type: new FormControl(this.notification.type)
      });
    } else {
      this.formTitle = 'Create Notification';
      this.notificationDataForm = this.fb.group({
        id: new FormControl(''),
        title: new FormControl('', Validators.required),
        body: new FormControl('', Validators.required),
        date_from: new FormControl('', Validators.required),
        date_until: new FormControl('',  Validators.required),
        url: new FormControl(''),
        type: new FormControl('')
      });
    }
  }

  editNotification(notificationId?, deleteNotification?) {
    this.loading = true;
    var data = {};
    var valid = this.validateFields();

    if (!valid) {
      this.loading = false;
      return false;
    }

    // send dates as iso string so the value saved in db is the same as the selected one
    var date_from = new Date(this.notificationDataForm.value.date_from);
    var date_until = new Date(this.notificationDataForm.value.date_until);
    var userTimezoneOffset = date_from.getTimezoneOffset() * 60000;
    var iso_date_from = new Date(date_from.getTime() - userTimezoneOffset).toISOString();
    var iso_date_until = new Date(date_until.getTime() - userTimezoneOffset).toISOString();

    // case delete notification
    if (notificationId && deleteNotification) {
      if(confirm("Are you sure to remove this notfication? This action can not be undone.")) {
        var n = this.notifications.find(n => n.id == notificationId);
        data = {
          id: n.id,
          title: n.title,
          body: n.body,
          date_from: iso_date_from,
          date_until: iso_date_until,
          url: n.url,
          type: n.type,
          delete: true
        }
      } else {
        this.loading = false;
        return true;
      }

    } else {
      // case edit notification
      data = {
        id: this.notificationDataForm.value.id,
        title: this.notificationDataForm.value.title,
        body: this.notificationDataForm.value.body,
        date_from: iso_date_from,
        date_until: iso_date_until,
        url: this.notificationDataForm.value.url,
        type: this.notificationDataForm.value.type
      }
    }

    this.apiService.put('admin/notification', null, data).subscribe(
      (result) => {
        if (result.error == "0") {
          // case success
          this.formErrorMsg = '';
          if (notificationId && deleteNotification) {
            this.openSnackBar("The notification was successfully removed", "OK");
          } else {
            this.openSnackBar("The data was successfully submitted", "OK");
          }
          // reload data
          this.listNotifications();
          this.router.navigate(["/notifications"]);
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.formErrorMsg = result.message;
          this.loading = false;
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.formErrorMsg = 'There was an internal error'
        this.loading = false;
      }
    )
  }

  addNotification(){
    this.loading = true;
    var valid = this.validateFields();

    if (!valid) {
      this.loading = false;
      return false;
    }

    var data = {
      id: this.notificationDataForm.value.id,
      title: this.notificationDataForm.value.title,
      body: this.notificationDataForm.value.body,
      date_from: '',
      date_until: '',
      url: this.notificationDataForm.value.url,
      type: this.notificationDataForm.value.type
    }

    if (this.notificationDataForm.value.date_from) {
      var date_from = new Date(this.notificationDataForm.value.date_from)
      var userTimezoneOffset = date_from.getTimezoneOffset() * 60000;
      data.date_from = new Date(date_from.getTime() - userTimezoneOffset).toISOString();
    }

    if (this.notificationDataForm.value.date_until) {
      var date_until = new Date(this.notificationDataForm.value.date_until)
      var userTimezoneOffset = date_until.getTimezoneOffset() * 60000;
      data.date_until = new Date(date_until.getTime() - userTimezoneOffset).toISOString();
    }

    this.apiService.post('admin/notification', data).subscribe(
      (result) => {
        if (result.error == "0") {
          // case success
          this.formErrorMsg = '';
          this.showNotificationsList = true;
          this.openSnackBar("The notification was successfully created", "OK");
          // reload data
          this.listNotifications();
          this.router.navigate(["/notifications"]);
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.formErrorMsg = result.message;
          this.loading = false;
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.formErrorMsg = 'There was an internal error'
        this.loading = false;
      }
    )
  }

  validateFields(){
    this.formErrorMsg = '';

    if (this.notificationDataForm.value.type == 'push') {
      if (!(this.notificationDataForm.value.title && this.notificationDataForm.value.body)) {
        this.formErrorMsg = 'Complete all required fields to continue';
      }
    } else {
      if (!(this.notificationDataForm.value.title && this.notificationDataForm.value.body && 
        this.notificationDataForm.value.date_from && this.notificationDataForm.value.date_until &&
        this.notificationDataForm.value.type)) {
          this.formErrorMsg = 'Complete all required fields to continue';
      }
    }

    if (!this.formErrorMsg) {
      return true;
    } else {
      return false;
    }
  }

  reloadPage() {
    window.location.reload();
  }

  /******************************************************************************/
  // ON/OFF FUNCTIONS

  showAddNotificationView() {
    this.showNotificationsList = false;
    this.showFormType = 'add';
    this.createNotificationForm('add');
  }


  /******************************************************************************/
  // POP UP MESSAGE

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

}
