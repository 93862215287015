<div class="row">    
    <div class="col-1 col-md-3"></div>
    <div class="col-10 col-md-6">
        <mat-card style="margin-top: 6rem;">
            <mat-card-header>
                <mat-card-title>Access Denied</mat-card-title>
            </mat-card-header>
            <mat-card-content class="dashboard-card-content">
                <form>
                    <h3>You have no permissions to access to this content.</h3>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-1 col-md-3"></div>
</div>