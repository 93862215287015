<mat-toolbar class="page-title mat-elevation-z1">
  <mat-toolbar-row>
    <span>Accounts</span>
  </mat-toolbar-row>
</mat-toolbar>

<!-- ACCOUNTS LIST -->
<div class="container mat-elevation-z3 accounts-list-box" *ngIf="showAccountsList">
  <!-- search account -->
  <form class="search-form">
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input type="text"
             placeholder="i.e. John Doe"
             aria-label="Number"
             matInput
             (input)="onSearch($event)">
    </mat-form-field>
  </form>

  <!-- loader -->
  <mat-spinner *ngIf="loading" style="top: 5rem; left: 45%;"></mat-spinner>
  
  <!-- accounts table -->
  <table mat-table class="full-width-table table-responsive" [style.opacity]="loading ? 0.1 : 1">
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef>Full Name</th>
      <td mat-cell *matCellDef="let row">{{row.fullName}}</td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone Number</th>
      <td mat-cell *matCellDef="let row">{{row.phone}}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email Address</th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>
    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let row">
        <button mat-mini-fab routerLink="/accounts/{{row.id}}">
          <mat-icon>perm_identity</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- paginator options -->
  <mat-paginator #paginator
      [length]="totalAccountsNumber"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[10, 25, 50]"
      (page)="paginatorChanged($event)">
  </mat-paginator>
</div>



<!--- ACCOUNT DETAILS -->

<!-- loader -->
<mat-spinner *ngIf="loading && !showAccountsList" style="top: 15rem; left: 45%;"></mat-spinner>

<!--- account detail box -->
<div class="container mat-elevation-z3 account-detail-box" *ngIf="!showAccountsList" [style.opacity]="loading ? 0.1 : 1">
  <mat-tab-group dynamicHeight animationDuration="1000ms">

    <!--- account details tab -->
    <mat-tab>
      <ng-template mat-tab-label>
        Account Details &nbsp;
        <mat-icon>info</mat-icon>
      </ng-template>

      <!-- personal data box -->
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>
                Personal Data &nbsp;
                <button mat-stroked-button class="mat-elevation-z3" (click)="createForm('personal_data', account)" *ngIf="!showFormPersonalData"
                  style="min-width: 0;border-radius: 100%; padding: 0.3rem; line-height: 0px; background-color: #e2eefa; bottom: 3px;">
                    <mat-icon style="font-size: 16px; width: 16px; height: 16px;">edit</mat-icon>
                </button>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <!--- list personal data -->
              <div class="row" *ngIf="!showFormPersonalData">
                <div class="col-sm-12 col-lg-6">
                  <h3>First Name: {{account.account.first_name}}</h3>
                  <h3>Last Name: {{account.account.last_name}}</h3>
                  <h3>Phone: {{account.account.phone}}</h3>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <h3>Email: {{account.account.email}}</h3>
                  <h3>Username: {{account.account.username}}</h3>
                  <h3>
                    <button mat-button style="padding: 0px; color: #0d2c6d;" (click)="sendPasswordRecoveryEmail()">
                      <mat-icon>mail_outline</mat-icon>
                      Send password recovery email
                    </button>
                  </h3>
                </div>
              </div>

              <!--- edit personal data  -->
              <form *ngIf="showFormPersonalData" [formGroup]="personalDataForm" id="PersonalDataFormID">
                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <input formControlName="id" matInput style="display: none;">
                    <mat-form-field>
                      <mat-label>First Name</mat-label>
                      <input formControlName="first_name" matInput>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Last Name</mat-label>
                      <input formControlName="last_name" matInput>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Phone</mat-label>
                      <input formControlName="phone" matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <mat-form-field>
                      <mat-label>Email</mat-label>
                      <input formControlName="email" matInput>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Username</mat-label>
                      <input formControlName="username" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-lg-6"></div>
                  <div class="col-sm-12 col-lg-6">
                    <button mat-stroked-button (click)="displayFormPersonalData(false)">Cancel</button>
                    &nbsp;
                    <button mat-flat-button (click)="editPersonalData()" style="background-color:#4BB543;">Edit</button>
                  </div>
                </div>
              </form>

            </mat-card-content>
          </mat-card>

          <!-- salesforce data box -->
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>
                Salesforce Account
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <!--- list salesforce data -->
              <div class="row" *ngIf="!showFormSalesforceData">
                <div class="col-sm-12 col-lg-6">
                  <h3> ID: {{account.salesforce.id}} &nbsp;
                    <button mat-stroked-button class="mat-elevation-z3" (click)="createForm('salesforce', account)" 
                      style="min-width: 0;border-radius: 100%; padding: 0.3rem; line-height: 0px; background-color: #e2eefa; bottom: 3px;">
                        <mat-icon style="font-size: 16px; width: 16px; height: 16px;">edit</mat-icon>
                    </button>
                  </h3>
                  <h3> Salesforce Name: {{account.salesforce.name}} </h3>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <h3> Billing Address: {{account.salesforce.BillingStreet}}, 
                    {{account.salesforce.BillingCity}}, {{account.salesforce.BillingState}} </h3>
                  <h3> Phone: {{account.salesforce.Phone}} </h3>
                </div>
              </div>

               <!--- edit personal data  -->
               <form *ngIf="showFormSalesforceData" [formGroup]="salesForceForm">
                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <input formControlName="id" matInput style="display: none;">
                    <mat-form-field>
                      <mat-label>Salesforce ID</mat-label>
                      <input formControlName="salesforce_id" matInput>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-lg-6"></div>
                  <div class="col-sm-12 col-lg-6">
                    <button mat-stroked-button (click)="displayFormSalesforceData(false)">Cancel</button>
                    &nbsp;
                    <button mat-flat-button (click)="editSalesforce()" style="background-color:#4BB543;">Edit</button>
                  </div>
                </div>
              </form>

            </mat-card-content>
          </mat-card>
        </div> 

        <!-- policies data box -->
        <div class="col-sm-12 col-lg-6">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Policies Data</mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="account.policies.length > 0">
              <div *ngFor="let p of account.policies">
                <div class="row">
                  <div class="col-sm-12 col-lg-6">
                    <h3 style="font-weight: 600;">{{p.name}}</h3>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <a mat-button href="https://magnumia.lightning.force.com/lightning/r/CanaryAMS__Policy__c/{{p.id}}/view"
                      target="_blank"  style="color: #0d2c6d;" >
                      View more in <i class="fa-brands fa-salesforce" style="font-size: 2.3rem;"></i>
                    </a>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <h3>Policy Number: {{p.policy_number}}</h3>
                    <h3>Status: {{p.status}}</h3>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <h3>Effective Date: {{p.policy_effective_date}}</h3>
                    <h3>Expiration Date: {{p.expiration_date}}</h3>
                  </div>
                </div>
              </div>
            </mat-card-content>
            <mat-card-content *ngIf="account.policies.length == 0">
              <div>
                <h3>There are no policies to show.</h3>
              </div>
            </mat-card-content>
          </mat-card>
        </div>   
      </div>
    </mat-tab>


    <!--- account activity Tab -->
    <mat-tab>
      <ng-template mat-tab-label>
        Account Activity &nbsp;
        <mat-icon>devices</mat-icon>
      </ng-template>
      <div class="row">

        <!-- Usage Data -->
        <div class="col-sm-12 col-lg-6">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Usage Data</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <div class="col-sm-12 col-lg-6">
                  <h3>Language: {{activity.language}}</h3>
                  <h3>Date Created: {{activity.date_created | date: 'MM/dd/yyyy HH::mm' }}</h3>
                  <h3>Last Activity: {{activity.last_activity | date: 'MM/dd/yyyy HH::mm'}}</h3>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <h3>Number of Logins: {{activity.login_counts ? activity.login_counts : 0}}</h3>
                  <h3>Last Login: {{activity.last_login | date: 'MM/dd/yyyy HH::mm'}}</h3>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        
        <!--- Recent Sessions -->
        <div class="col-sm-12 col-lg-6">
          <mat-card class="mat-elevation-z2">
            <mat-card-header>
              <mat-card-title>Recent Sessions</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="row">
                <table mat-table [dataSource]="activity.last_sessions">
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let row">{{row.created_at | date: 'MM/dd/yyyy HH:mm'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="platform">
                    <th mat-header-cell *matHeaderCellDef>Platform</th>
                    <td mat-cell *matCellDef="let row">{{row.platform}}</td>
                  </ng-container>
                  <ng-container matColumnDef="os">
                    <th mat-header-cell *matHeaderCellDef>OS</th>
                    <td mat-cell *matCellDef="let row">{{row.os}}</td>
                  </ng-container>
                  <ng-container matColumnDef="version">
                    <th mat-header-cell *matHeaderCellDef>OS Version</th>
                    <td mat-cell *matCellDef="let row">{{row.version}}</td>
                  </ng-container>
                  <ng-container matColumnDef="push">
                    <th mat-header-cell *matHeaderCellDef>Push is active</th>
                    <td mat-cell *matCellDef="let row">{{row.has_push ? 'yes' : 'no'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="language">
                    <th mat-header-cell *matHeaderCellDef>Language</th>
                    <td mat-cell *matCellDef="let row">{{row.language}}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="sessionsColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: sessionsColumns;"></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </div>
    </mat-tab>

  </mat-tab-group>
</div>
