import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-troubleshoot',
  templateUrl: './troubleshoot.component.html',
  styleUrls: ['./troubleshoot.component.css']
})
export class TroubleshootComponent implements OnInit {

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router) { }

  loading = false;
  showResults = false;

  selectedMethod = '';
  selectedMethodValue = '';
  selectedMethodLabel = 'Value';
  methods = [
    {value: 'policy_number', label: 'Policy Number'},
    {value: 'policy_id', label: 'Policy ID'},
    {value: 'contact_email', label: 'Contact Email'},
    {value: 'account_phone', label: 'Account Phone'},
    {value: 'account_id', label: 'Account ID'},
  ];
  searchErrorMsg = '';

  results = {};

  ngOnInit(): void {
    // get query params in case url is shared
    // query params vs path variables: https://stackoverflow.com/a/48125257
    this.activatedRoute.queryParams.subscribe(params => {
      this.selectedMethod = params['method'];
      this.selectedMethodValue = params['value'];
      if (params['method'] && params['value']) {
        this.search();
      }
  });
  }

  updateValuePlaceholder() {
    var m = this.methods.find( m => m.value == this.selectedMethod);   
    this.selectedMethodLabel = m.label;
  }

  search(){
    if (this.validateFields()) {
      this.updatePageUrl();
      this.showResults = false;
      this.loading = true;

      var data = {
        method: this.selectedMethod,
        value: this.selectedMethodValue
      }

      this.apiService.get('admin/salesforce-debug', data).subscribe(
        (result) => {
          if (result.error == "0") {
            this.results = {};
            this.results = result;
            this.loading = false;
            if (result.data.length > 0) {
              this.showResults = true;
            } else {
              this.searchErrorMsg = 'No results were found'
            }
          }
          if (result.error == "1") {
            console.log("error");
            console.log(result.message);
            this.loading = false;
            this.searchErrorMsg = 'No results were found'
          }
        },
        (error) => {
          console.log("error");
          console.log(error);
          this.loading = false;
          this.searchErrorMsg = 'There was an internal error'
        }
      );
    };
  }

  validateFields(){
    if (!this.selectedMethod || !this.selectedMethodValue) {
      this.searchErrorMsg = 'All fields must be completed';
      return false;
    } else {
      this.searchErrorMsg = '';
      return true;
    }
  }

  updatePageUrl() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { method: this.selectedMethod, value: this.selectedMethodValue },
      queryParamsHandling: 'merge'
    });
  }

}
