import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );

  sessionUserName = '';

  dashboardData = {};
  displayedColumns = ['name', 'value'];

  showView = false;
  loading = false;

  constructor(private breakpointObserver: BreakpointObserver, 
    private route: ActivatedRoute,  private router: Router, private apiService: ApiService) {}

  async ngOnInit(){
      this.loading = true;

      // check if token is received as param
      var paramToken = this.route.snapshot.params.session_token;
      console.log(paramToken);

      if (paramToken) {
        await this.validateToken(paramToken);
      } else {
        // check if token is in local storage
        var storageToken = localStorage.getItem('session_token');
        if (storageToken) {
          await this.validateToken(storageToken);
        } else {
          // if it's not, user can't access
          this.router.navigate(['/access-denied'])
        }
      }
  }


  validateToken(token) {
    this.apiService.get('admin/check-user', null, token).subscribe(
      (result) => {
        if (result.error == "0") {
          // token is valid, save data in local storage
          localStorage.setItem('session_token', token);
          localStorage.setItem('session_user_name', result.user.name);
          localStorage.setItem('session_user_role', result.user.role);
          this.sessionUserName = result.user.name;

          // update navbar view
          this.apiService.authenticate();
          this.apiService.setSessionUserName(this.sessionUserName);
          if (result.user.role == 'Admin') {
            this.apiService.setSessionUserRoleIsAdmin(true); 
          } else {
            this.apiService.setSessionUserRoleIsAdmin(false);
          }

          // load dashboard data
          this.loadDashboardData();
        } else {
          // token is invalid, user can't access
          this.router.navigate(['/access-denied'])
        }
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/access-denied'])
      }
    )
  }


  loadDashboardData() {
    this.apiService.get('admin/stats', null).subscribe(
      (result) => {
        if (result.error == "0") {
          this.dashboardData = result.data;
          console.log(result);
        } else {
          console.log(result);
        }
        this.loading = false;
        this.showView = true;
      },
      (error) => {
        console.log(error);
        this.router.navigate(['/access-denied'])
      }
    )
  }

}
