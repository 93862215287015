import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AuthGuard } from './auth.guard';
import { TroubleshootComponent } from './troubleshoot/troubleshoot.component';
import { NotificationsComponent } from './notifications/notifications.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard/', pathMatch: 'full' },
  { path: 'dashboard', redirectTo: 'dashboard/', pathMatch: 'full' },
  { path: 'dashboard/:session_token', component: DashboardComponent },
  { path: 'accounts', component: AccountsComponent, canActivate: [AuthGuard] },
  { path: 'accounts/:id', component: AccountsComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'notifications/:id', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'troubleshoot', component: TroubleshootComponent, canActivate: [AuthGuard] },
  { path: 'access-denied', component: AccessDeniedComponent },
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
