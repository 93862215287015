import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private route: ActivatedRoute, private router: Router, private apiService: ApiService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): 
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // check if current token is valid
      this.apiService.get('admin/check-user', null).subscribe(
          (result) => {
            if (result.error == "0") {

              if (this.router.url.includes('notifications')) {
                // only admin users must have access to notifications section
                if (result.user.role != 'Admin') {
                  this.router.navigate(['/access-denied'])  
                } else {
                  return true;
                }
              } else {
                return true;
              }

            } else {
              this.router.navigate(['/access-denied'])
            }
          },
          (error) => {
            console.log(error);
            this.router.navigate(['/access-denied'])
          }
      )
      return true;
  }
  
}
