<mat-sidenav-container class="sidenav-container">
  <mat-sidenav-content>
    <mat-toolbar>

      <!-- Mobile menu -->
      <button mat-button class="d-xs-block d-sm-block d-md-block d-lg-none" [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/dashboard">
          Home
        </button>
        <button mat-menu-item routerLink="/accounts">
          Accounts
        </button>
        <button mat-menu-item routerLink="/troubleshoot">
          Troubleshoot
        </button>
        <button mat-menu-item routerLink="/notifications" 
          *ngIf="((session_user_role == 'Admin') || ((apiService.sessionUserRoleIsAdmin$ | async) === true))">
          Notifications
        </button>
        <hr>
        <button mat-menu-item>
          {{session_user_name ? session_user_name : (apiService.sessionUserName$ | async)}}
        </button>
      </mat-menu>

      <!-- Logo -->
      <img src="https://magnuminsurance.com/wp-content/uploads/2020/05/magnum-logo.svg" height="70" style="margin-bottom: 10px;">
      
      <!-- Desktop menu -->
      <div class="nav-options-container d-none d-lg-block" style="position: absolute; right: 2rem;"
        *ngIf="((apiService.authenticated$ | async) === true) || session_token">
        <button mat-icon-button routerLink="/dashboard">
          Home
        </button>
        <button mat-icon-button routerLink="/accounts">
          Accounts
        </button>
        <button mat-icon-button routerLink="/troubleshoot">
          Troubleshoot
        </button>
        <button mat-icon-button routerLink="/notifications" 
          *ngIf="((session_user_role == 'Admin') || ((apiService.sessionUserRoleIsAdmin$ | async) === true))">
          Notifications
        </button>
        |
        <button mat-icon-button>
          {{session_user_name ? session_user_name : (apiService.sessionUserName$ | async)}}
        </button>
        <!-- <button mat-icon-button (click)="logout()">
          Logout
        </button> -->
      </div>
    </mat-toolbar>


    <!-- App content -->
    <div>
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>


