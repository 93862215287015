<mat-toolbar class="page-title mat-elevation-z1">
  <mat-toolbar-row>
    <span>Notifications</span>
  </mat-toolbar-row>
</mat-toolbar>

<!-- NOTIFICATIONS LIST -->
<div class="container mat-elevation-z3 notifications-list-box" *ngIf="showNotificationsList">  
    <!-- loader -->
    <mat-spinner *ngIf="loading" style="top: 5rem; left: 45%;"></mat-spinner>

    <!-- notifications table -->
    <table mat-table class="full-width-table table-responsive notifications-table" [style.opacity]="loading ? 0.1 : 1">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let row">{{row.title}}</td>
      </ng-container>
      <ng-container matColumnDef="body">
        <th mat-header-cell *matHeaderCellDef style="width: 40% !important;">Body</th>
        <td mat-cell *matCellDef="let row" style="width: 40% !important;">{{row.body}}</td>
      </ng-container>
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef>URL</th>
        <td mat-cell *matCellDef="let row">{{row.url}}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row">
          {{row.type == 'push' ? 'Push' : ''}}
          {{row.type == 'in_app' ? 'In App' : ''}}
          {{row.type == 'all' ? 'All' : ''}}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateFrom">
        <th mat-header-cell *matHeaderCellDef>Date From</th>
        <td mat-cell *matCellDef="let row">{{ (row.date_from) ? (row.date_from | date:'MM-dd-yyyy HH:mm') : "" }}</td>
      </ng-container>
      <ng-container matColumnDef="dateUntil">
        <th mat-header-cell *matHeaderCellDef>Date Until</th>
        <td mat-cell *matCellDef="let row">{{ (row.date_until) ? (row.date_until | date:'MM-dd-yyyy HH:mm') : "" }}</td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row">
          <button mat-mini-fab routerLink="/notifications/{{row.id}}">
            <mat-icon>edit</mat-icon>
          </button>
          &nbsp;
          <button mat-mini-fab (click)="editNotification(row.id, true)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="row">
      <div class="col-12" style="text-align: right;">
        <br>
        <button mat-fab color="primary" (click)="showAddNotificationView()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
</div>


<!--- NOTIFICATION DETAILS -->

<!-- loader -->
<mat-spinner *ngIf="loading && !showNotificationsList" style="top: 15rem; left: 45%;"></mat-spinner>

<!--- notification detail box -->
<div class="container mat-elevation-z3 notification-detail-box" *ngIf="!showNotificationsList" [style.opacity]="loading ? 0.1 : 1">
  <mat-tab-group dynamicHeight animationDuration="1000ms">

    <!--- notification details tab -->
    <mat-tab>
      <ng-template mat-tab-label>
        <b style="color: #000000;">{{formTitle}}</b> &nbsp;
      </ng-template>

      <!-- personal data box -->
      <div class="row">
        <div class="col-sm-12 offset-lg-2 col-lg-8">

          <!--- edit personal data  -->
          <form [formGroup]="notificationDataForm" id="NotificationDataFormID" *ngIf="notificationDataForm">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <input formControlName="id" matInput style="display: none;">
                <mat-form-field>
                  <mat-label>Type <span class="required-field">*</span></mat-label>
                  <mat-select formControlName="type" [disabled]="showFormType != 'add'">
                    <mat-option value="push"> Push </mat-option>
                    <mat-option value="in_app"> In App </mat-option>
                    <mat-option value="all"> All </mat-option>
                  </mat-select>
                </mat-form-field>
                <br><br>
                <mat-form-field>
                  <mat-label>Title <span class="required-field">*</span></mat-label>
                  <input formControlName="title" #title maxlength="50" matInput>
                  <mat-hint align="end">{{title.value.length}} / 50</mat-hint>
                </mat-form-field>
                <br><br>
                <mat-form-field>
                  <mat-label>Body <span class="required-field">*</span></mat-label>
                  <textarea formControlName="body" #body matInput maxlength="120" rows="4"></textarea>
                  <mat-hint align="end">{{body.value.length}} / 120</mat-hint>
                </mat-form-field>
              </div>
              <div class="col-sm-12 col-lg-6">
                <mat-form-field *ngIf="(notificationDataForm.value.type && notificationDataForm.value.type != 'push')">
                  <mat-label>Show In App From <span class="required-field">*</span></mat-label>
                  <input matInput type="datetime-local" formControlName="date_from">
                  <mat-datepicker-toggle matIconSuffix [for]="picker_from"></mat-datepicker-toggle>
                  <mat-datepicker #picker_from></mat-datepicker>
                </mat-form-field>
                <br><br><br>
                <mat-form-field *ngIf="(notificationDataForm.value.type && notificationDataForm.value.type != 'push')">
                  <mat-label>Show In App Until <span class="required-field">*</span></mat-label>
                  <input matInput type="datetime-local" formControlName="date_until">
                  <mat-datepicker-toggle matIconSuffix [for]="picker_until"></mat-datepicker-toggle>
                  <mat-datepicker #picker_until></mat-datepicker>
                </mat-form-field>
                <br><br>
                <mat-form-field *ngIf="(notificationDataForm.value.type && notificationDataForm.value.type != 'push')">
                  <mat-label>URL</mat-label>
                  <input formControlName="url" matInput>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 offset-lg-6 col-lg-6" *ngIf="formErrorMsg">
                <br>
                <h3 class="alert-error">
                    {{formErrorMsg}}
                </h3>
              </div>
              <div class="col-sm-12 offset-lg-6 col-lg-3">
                <br>
                <button mat-stroked-button class="form-btns" routerLink="/notifications" *ngIf="showFormType == 'edit'">
                  Cancel
                </button>
                <button mat-stroked-button class="form-btns" (click)="reloadPage()" *ngIf="showFormType == 'add'">
                  Cancel
                </button>
              </div>
              <div class="col-sm-12 col-lg-3">
                <br>
                <button mat-flat-button class="form-btns" (click)="editNotification()" 
                  style="background-color:#4BB543;" *ngIf="showFormType == 'edit'">Edit</button>
                <button mat-flat-button class="form-btns" (click)="addNotification()" 
                  style="background-color:#4BB543;" *ngIf="showFormType == 'add'">
                  {{ (notificationDataForm.value.type == 'push' || notificationDataForm.value.type == 'all') ? 'Create & Push Now' : 'Create'}}
                </button>
              </div>
            </div>
          </form>
          
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>