<mat-toolbar class="page-title mat-elevation-z1">
    <mat-toolbar-row>
      <span>Troubleshoot</span>
    </mat-toolbar-row>
</mat-toolbar>

<br><br>
<div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Search Account
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form>
                <div class="row">
                    <div class="col-sm-12 col-md-2">
                        <mat-form-field>
                            <mat-label>Search By</mat-label>
                            <mat-select [(ngModel)]="selectedMethod" (ngModelChange)="updateValuePlaceholder()" name="method">
                              <mat-option *ngFor="let m of methods" [value]="m.value">
                                {{m.label}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-2">
                        <mat-form-field>
                            <input matInput [(ngModel)]="selectedMethodValue" name="methodValue" placeholder="Insert {{selectedMethodLabel}}">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-2">
                        <button mat-button type="button" (click)="search()">Search</button>
                    </div>
                </div>
                <div class="row" *ngIf="searchErrorMsg">
                    <div class="col-sm-12 col-md-6">
                        <div class="alert-error">
                            {{searchErrorMsg}}
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-1"></div>
</div>

<!-- loader -->
<mat-spinner *ngIf="loading" style="top: 5rem; left: 45%;"></mat-spinner>

<br><br>
<div class="row" *ngIf="showResults">
    <div class="col-1"></div>
    <div class="col-10">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Accounts:
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>

            <!-- Account accordion -->
            <mat-accordion *ngFor="let a of results.data">
                <mat-expansion-panel class="account-expansion-panel">
                    <!-- Account name -->
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <b>
                                {{a.name}} - 
                                <span class="account-status-{{a.active}}">
                                    {{a.active == 'YES' ? 'Active' : 'Inactive'}}
                                </span>
                            </b>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <!-- Account data -->
                    <div class="row">
                        <div class="col-12 col-lg-3">
                          <h3>Account ID: {{a.id}}</h3>
                          <h3>First Name: {{a.first_name}}</h3>
                          <h3>Last Name: {{a.last_name}}</h3>
                          <h3>Phone: {{a.phone}}</h3>
                        </div>
                        <div class="col-12 col-lg-3">
                            <h3>Street: {{a.billing_address.street}}</h3>
                            <h3>City: {{a.billing_address.city}}</h3>
                            <h3>State: {{a.billing_address.state}}</h3>
                            <h3>Postal Code: {{a.billing_address.postalCode}}</h3>
                        </div>
                        <div class="col-12 col-lg-6" *ngIf="a.alert_message">
                            <h3 class="alert-error">
                                {{a.alert_message}}
                            </h3>
                        </div>
                    </div>

                    <!--- Account policies -->
                    <div class="row">
                        <div class="col-12">
                            <h2>Policies:</h2>
                            <mat-accordion *ngFor="let p of a.policies">
                                <mat-expansion-panel class="policy-expansion-panel">
                                    <!-- Policy name -->
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <b>
                                                {{p.name}} - 
                                                <span class="policy-status-{{p.status}}">
                                                    {{p.status}}
                                                </span>
                                            </b>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                
                                    <!-- Policy data -->
                                    <div class="row">
                                        <div class="col-12 col-lg-3">
                                          <h3>Policy ID: {{p.id}}</h3>
                                          <h3>Policy Name: {{p.name}}</h3>
                                          <h3>Policy Number: {{p.policy_number}}</h3>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <h3>Effective Date: {{p.effective_date}}</h3>
                                            <h3>Renewal Date: {{p.renewal_date}}</h3>
                                            <h3>Cancellation: {{p.cancellation_date}}</h3>
                                        </div>
                                        <div class="col-12 col-lg-6" *ngIf="p.alert_message">
                                            <h3 class="alert-error">
                                                {{p.alert_message}}
                                            </h3>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>

        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-1"></div>
  </div>

