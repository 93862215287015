import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountsItem } from './accounts-datasource';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable) table!: MatTable<AccountsItem>;

  // accounts list
  accounts = [];
  filters = {
    page: 1,
    pageSize: 10,
    search: ''
  };
  totalAccountsNumber = 0;
  totalAccountsPages = 0;
  currentAccountsPage = 0;
  displayedColumns = ['fullName', 'phone', 'email', 'options'];

  // account details
  accountId;
  account = {
    account: {
      username: ''
    }
  };
  personalDataForm: FormGroup;
  salesForceForm: FormGroup;

  // account activity details
  activity = {};
  sessionsColumns = ['date', 'platform', 'os', 'version', 'push', 'language'];

  // on/off variables
  loading = false;
  showAccountsList = true;
  showFormPersonalData = false;
  showFormSalesforceData = false;

  // token
  token = localStorage.getItem('session_token');

  constructor(private activatedRoute: ActivatedRoute, 
    private apiService: ApiService, 
    private fb: FormBuilder, 
    private snackBar: MatSnackBar) {}

  ngOnInit() {    
    this.accountId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.accountId) {
      this.showAccountsList = false;
      this.getAccountData();
    }
    if (!this.accountId) {
      this.listAccounts();
    }
  }


  /******************************************************************************/
  // ACCOUNTS LIST
  
  listAccounts() {
    this.loading = true;
    this.apiService.get('admin/accounts', this.filters).subscribe(
      (result) => {
        if (result.error == "0") {
          // update paginator
          this.totalAccountsNumber = result.totalPages * this.filters.pageSize;
          this.currentAccountsPage = result.currentPage;
          this.totalAccountsPages = result.totalPages;
          const list = document.getElementsByClassName('mat-paginator-range-label');
          list[0].innerHTML = 'Page ' + this.currentAccountsPage + ' of '+ this.totalAccountsPages;

          // update accounts list
          this.accounts = [];
          result.data[0].forEach(a => {
            var acc = {
              id: a.id,
              fullName: a.first_name+' '+a.last_name,
              phone: a.phone,
              email: a.email
            }
            this.accounts.push(acc);
          });
          this.table.dataSource = this.accounts;
          this.loading = false;
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.loading = false;
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.loading = false;
      }
    );
  }

  onSearch(e) {
    this.filters.search = e.target.value.toLowerCase();
    this.listAccounts();
  }
  
  paginatorChanged(e) {
    this.filters.page = e.pageIndex + 1;
    this.filters.pageSize = e.pageSize;
    this.listAccounts();
  }


  /******************************************************************************/
  // ACCOUNT DETAILS
  
  getAccountData() {
    this.loading = true;
    this.apiService.get('admin/account/'+this.accountId, null).subscribe(
      (result) => {
        if (result.error == "0") {
          this.account = result.data;
          this.getAccountActivityData();
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.loading = false;
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.loading = false;
      }
    );
  }

  createForm(type, a) {
    switch (type) {
      case 'personal_data':
        this.personalDataForm = this.fb.group({
          id: new FormControl(a.account.id, Validators.required),
          first_name: new FormControl(a.account.first_name, Validators.required),
          last_name: new FormControl(a.account.last_name, Validators.required),
          phone: new FormControl(a.account.phone, Validators.required),
          email: new FormControl(a.account.email, Validators.required),
          username: new FormControl(a.account.username, Validators.required)
        });
        this.displayFormPersonalData(true)    
        break;
      case 'salesforce':
        this.salesForceForm = this.fb.group({
          id: new FormControl(a.account.id, Validators.required),
          salesforce_id: new FormControl(a.salesforce.id, Validators.required)
        });
        this.displayFormSalesforceData(true)
        break;
      default:
        break;
    }
  }

  // unused function atm
  personalDataShowInvalidControlsOrSubmit(){
    let invalid = [];
    this.personalDataForm.updateValueAndValidity();

    if(this.personalDataForm.valid){
      console.log('formulario valido');
      this.editPersonalData();
      return;
    }

    let scrolled = false;
    Object.keys(this.personalDataForm.controls).forEach(key => {
      this.personalDataForm.controls[key].updateValueAndValidity();
      if(!this.personalDataForm.controls[key].valid && this.personalDataForm.controls[key].enabled){
        this.personalDataForm.controls[key].markAsTouched();
        invalid.push(key);
        if(!scrolled){
          scrolled = true;
          let form = document.getElementById('PersonalDataFormID');
          let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
          try{
            firstInvalidControl.scrollIntoView();
            (firstInvalidControl as HTMLElement).focus()
          }
          catch(er){
            console.log("Error scrolleando a " + key);
          }
        }
      }
    });
    console.log('invalids: ' + invalid);
  }

  editPersonalData() {
    this.loading = true;

    var data = {
      id: this.personalDataForm.value.id,
      first_name: this.personalDataForm.value.first_name,
      last_name: this.personalDataForm.value.last_name,
      phone: this.personalDataForm.value.phone,
	    email: this.personalDataForm.value.email,
	    username: this.personalDataForm.value.username
    }

    this.apiService.post('admin/account', data).subscribe(
      (result) => {
        if (result.error == "0") {
          console.log(result);
          this.displayFormPersonalData(false);
          this.getAccountData();
          this.openSnackBar('Data successfully updated', 'OK');
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.displayFormPersonalData(false);
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.displayFormPersonalData(false);
      }
    )
  }

  editSalesforce() {
    this.loading = true;

    var data = {
      id: this.salesForceForm.value.id,
      salesforce_id: this.salesForceForm.value.salesforce_id
    }

    this.apiService.post('admin/salesforce', data).subscribe(
      (result) => {
        if (result.error == "0") {
          this.displayFormSalesforceData(false);
          this.openSnackBar('Data successfully updated', 'OK');
          location.reload();
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.displayFormSalesforceData(false);
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.displayFormSalesforceData(false);
      }
    )
  }

  sendPasswordRecoveryEmail() {
    this.loading = true;
    var data = {
      email: this.account.account.username
    }

    this.apiService.get('auth/password-recovery-request', data).subscribe(
      (result) => {
        if (result.error == "0") {
          this.loading = false;
          this.openSnackBar('Email has been sent', 'OK');
        }
        if (result.error == "1") {
          this.loading = false;
          console.log("error");
          console.log(result.message);
          this.openSnackBar('Could not send email', 'OK');
        }
      },
      (error) => {
        this.loading = false;
        console.log("error");
        console.log(error);
        this.openSnackBar('Could not send email', 'OK');
      }
    )
  }


  /******************************************************************************/
  // ACCOUNT ACTIVITY DETAILS

  getAccountActivityData() {
    this.apiService.get('admin/account-activity/'+this.accountId, null).subscribe(
      (result) => {
        if (result.error == "0") {
          this.activity = result.data;
          this.loading = false;
        }
        if (result.error == "1") {
          console.log("error");
          console.log(result.message);
          this.loading = false;
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
        this.loading = false;
      }
    );
  }


  /******************************************************************************/
  // ON/OFF FUNCTIONS

  displayFormPersonalData(value){
    this.showFormPersonalData = value;
  }

  displayFormSalesforceData(value){
    this.showFormSalesforceData = value;
  }  


  /******************************************************************************/
  // POP UP MESSAGE

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

}
