import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // protected API_URL = 'https://magnum-api.zebrastudio.agency/api/';
  protected API_URL = 'https://apiv2.magnuminsurance.com/api/';

  // flag variables for showing navbar data after authentication
  authenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  sessionUserName$: BehaviorSubject<string> = new BehaviorSubject('');
  sessionUserRoleIsAdmin$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public httpClient: HttpClient,
  ) {}


  get(method: string, data: any, bearer?: any): Observable<any> {
    // in case we are authenticating
    var session_token = localStorage.getItem('session_token');
    var token = session_token;
    if (bearer) {
      token = bearer
    }

    return this.httpClient.get(this.API_URL + method, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params: data
    });
  }

  post(method: string, data: any): Observable<any> {
    var session_token = localStorage.getItem('session_token');
    return this.httpClient.post(this.API_URL + method, data, {
      headers: {
        Authorization: 'Bearer ' + session_token,
      }
    });
  }

  put(method: string, body: any, params: any): Observable<any> {
    var session_token = localStorage.getItem('session_token');
    return this.httpClient.put(this.API_URL + method, body, {
      headers: {
        Authorization: 'Bearer ' + session_token,
      },
      params: params
    });
  }

  public authenticate() {
    this.authenticated$.next(true);
  }

  public setSessionUserName(name) {
    this.sessionUserName$.next(name);
  }

  public setSessionUserRoleIsAdmin(role) {
    this.sessionUserRoleIsAdmin$.next(role);
  }

}
