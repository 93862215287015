<mat-toolbar class="page-title mat-elevation-z1" *ngIf="showView">
  <mat-toolbar-row>
    <span>Dashboard - {{sessionUserName}}</span>
  </mat-toolbar-row>
</mat-toolbar>

<!-- loader -->
<mat-spinner *ngIf="loading" style="top: 5rem; left: 45%;"></mat-spinner>

<!-- small boxes -->
<div class="row dashboard-small-boxes" *ngIf="showView">
  <div class="col-sm-12 col-lg-4">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Number of Accounts
          <h1><strong>{{dashboardData.unique_accounts | number}}</strong></h1>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
  <div class="col-sm-12 col-lg-4">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Active Sessions
          <h1><strong>{{dashboardData.active_sessions | number}}</strong></h1>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
  <div class="col-sm-12 col-lg-4">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Total Sessions
          <h1><strong>{{dashboardData.sessions_count | number}}</strong></h1>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
</div>


<!-- big boxes -->
<div class="row dashboard-big-boxes" *ngIf="showView">

  <!-- language table -->
  <div class="col-sm-12 col-lg-3">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Sessions By Language
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
        <table mat-table [dataSource]="dashboardData.sessions_by_lang" class="full-width-table">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Language</th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let row">{{row.value | number}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>

  <!--- platform table  -->
  <div class="col-sm-12 col-lg-3">
    <mat-card class="card-sessions-by-platform">
      <mat-card-header>
        <mat-card-title>
          Sessions By Platform
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
        <mat-card-content class="dashboard-card-content">
          <table mat-table [dataSource]="dashboardData.sessions_by_platform" class="full-width-table">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Platform</th>
              <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>Total</th>
              <td mat-cell *matCellDef="let row">{{row.value | number}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card-content>
    </mat-card>
  </div>

  <!--- os table -->
  <div class="col-sm-12 col-lg-3">
    <mat-card class="card-sessions-by-os">
      <mat-card-header>
        <mat-card-title>
          Sessions By OS
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
        <mat-card-content class="dashboard-card-content">
          <mat-card-content class="dashboard-card-content">
            <table mat-table [dataSource]="dashboardData.sessions_by_os" class="full-width-table">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>OS</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Total</th>
                <td mat-cell *matCellDef="let row">{{row.value | number}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </mat-card-content>
        </mat-card-content>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- version table -->
  <div class="col-sm-12 col-lg-3">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Sessions By App Version
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="dashboard-card-content">
        <mat-card-content class="dashboard-card-content">
          <mat-card-content class="dashboard-card-content">
            <table mat-table [dataSource]="dashboardData.sessions_by_version" class="full-width-table">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Version</th>
                <td mat-cell *matCellDef="let row">{{row.name}}</td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Total</th>
                <td mat-cell *matCellDef="let row">{{row.value | number}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </mat-card-content>
        </mat-card-content>
      </mat-card-content>
    </mat-card>
  </div>

</div>